
@keyframes gradient {
	from {
		background-size: 100% 100%;
	}
	to {
		background-size: 125% 125%;
	}
}

body{
  position: relative;
  min-height: 100vh;
  background: #221144;
  color: #fff;
  cursor: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  // opacity: 0;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  h1{
    // font-family: 'Bebas Neue', cursive;
    // @include font-size(40px);
    // text-transform: uppercase;
    // font-weight: bold;
    // &.logo{
    //   a{
    //     text-decoration: none;
    //   }
    // }
    font-size: 2rem;
    text-transform: uppercase;
    font-family: 'Bebas Neue', sans-serif;
  }
  h2{
    font-size: 1.5rem;
    text-transform: uppercase;
    font-family: 'Bebas Neue', sans-serif;
  }
  h3{
    @include font-size(22px);
  }
  h4{
    @include font-size(20px);
  }

  p{
    @include font-size(17px);
    margin-bottom: 1.5rem;
  }
  a{
    color: #fff;
  }
  header{
    opacity: 0;
  }
  footer{
    opacity: 0;
  }
  main{
    height: 100%;
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    &>section{
      width: 100%;
    }
    .content{
      height: 100%;
      display: flex;
      // align-items: st;
      justify-content: center;
      flex-direction: column;
      max-width: 50%;
      margin: 20px auto;
      @media (max-width: 1400px){
        max-width: 90%;
      }
      h1{
        text-align: center;
      }
      h2,h1{
        margin-bottom: 2rem;
        // text-align: center;
        line-height: 2rem;
        font-family: 'Bebas Neue', sans-serif;
      }
      h3{
        margin-bottom: 1.8rem;
        // text-align: center;
        line-height: 1.8rem;
        font-family: 'Raleway', sans-serif;
        font-weight: 800;
      }
      p{
        // text-align: center;
        line-height: 1.8rem;
      }
      strong{
        font-family: 'Raleway', sans-serif;
        font-weight: 800;
      }
    }
  }
  .container{
    max-width: 90%;
    margin: 0 auto;
  }
  .fairy{
    position: fixed;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    z-index: -1;
    overflow: hidden;
    .grad{
      pointer-events: none;
      position: fixed;
      width: 180%;
      height: 180%;
      background-position: center center;
      background: rgb(153,150,113);
      // background: radial-gradient(circle, rgba(153,150,113,1) 0%, rgba(151,57,115,1) 8%, rgba(134,35,112,1) 19%, rgba(165,57,98,1) 42%, rgba(154,78,118,1) 61%, rgba(124,130,172,1) 100%) center no-repeat;
      background: radial-gradient(circle, #30F3C7 -10%, #3A4D98 10%, #221144 23%) center no-repeat;
      // background: radial-gradient(circle, #30F3C7 -1%, #38B6FF 3%, #3A4D98 10%, #221144 100%) center no-repeat;
      z-index: -1;
      animation: gradient 1.2s cubic-bezier( 0.34, 0.1, 0.4, 0.73 ) infinite alternate;

      opacity: 0;
    }

    .dust {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 0;
    }

    .dust .dot{
      position: absolute;
      border-radius: 50%;
      // background-color: hsl(60, 100%, 80%);
      background-color: #d6eeee;
      z-index: 0;
    }

  }
  .fairy-cursor{
    position: fixed;
    width: 50px;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-self: center;
    z-index: 1;

    // opacity: 0;
    path{
      // fill: #973973;
    }
    &:nth-child(2){
      opacity: .5;
    }
    &:nth-child(3){
      opacity: .3;
    }
  }

  &.is-mobile{
    // h1{
    //   display: inline-block;
    //   transform: translateX(40px);
    // }
    .fairy{
      position: fixed;
      z-index: -1;
      height: 100vh;
    }
  }
  form{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    .form-inputs-wrapper{
      display: flex;
      flex-direction: column;
    }
    h4{
      margin-bottom: 1rem;
      margin-top: 1rem;
      text-align: center;
    }
    .form-input,
    button{
      margin: 1rem 0;
      color: #fff;
      text-decoration: none;
      border-radius: 50px;
      padding: 20px 25px;
      @include font-size(13px);
      text-transform: uppercase;
      transition: all .3s ease;

      background-color: rgba(255,255,255,0.3);
      border: none;
      outline: none;
    }
    .form-input{
      border:1px solid rgba(255,255,255,0.3);
      background-color: transparent;
    }
    textarea{
      font-family: 'Optima';
    }
    button{
      width: fit-content;
      margin: 0 auto;
      cursor: pointer;
      &:hover{
        transform: scale(1.02);
      }
    }
  }

  .--secondary-btn{
    a{
      color: #221144!important;
    }
  }

  ::-webkit-input-placeholder {
    color: #FFF;
  }

  :-ms-input-placeholder {
    color: #FFF;
  }

  ::placeholder {
    color: #FFF;
  }
}