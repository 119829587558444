body.archive{
  main{
    align-items: start;
    margin-top: 50px;
    .content{
      h2{
        margin-bottom: 4rem;
        text-align: center;
      }
    }
  }

  .event{
    margin-bottom: 40px;      
    h3{
      border-radius: 50px;
      padding: 20px 25px;
      background-color: rgba(255,255,255,0.3);
      display: flex;
      justify-content: center;
      position: relative;
      text-transform: none;
      margin-bottom: 0;
      @media (max-width: 550px){
        padding: 20px 35px;
      }
      span{
        position: absolute;
        right: 40px;        
        font-size: 20px;
        font-weight: 900;
        height: 100%;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 550px){
          right: 15px;
        }
      }
    }
    .event-content{
      height: 0;
      overflow: hidden;      
      // margin-bottom: 40px;      
      &>p{
        margin-top: 4rem;
      }      
    }
  }  
}