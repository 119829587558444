/**
* Importing by selector filtering example
* https://github.com/maoberlehner/node-sass-magic-importer/tree/master/packages/node-sass-magic-importer#selector-filtering
 */
// @import '{ .util-color-red, .util-color-blue } from utils/_example-1.scss';

/**
* Importing by glob example
* https://github.com/maoberlehner/node-sass-magic-importer/tree/master/packages/node-sass-magic-importer#globbing
 */
// @font-face {
//   font-family: 'Optima';
//   font-style: normal;
//   font-weight: normal;
//   src: local('Optima'), url('../fonts/OPTIMA.woff') format('woff');
// }
// @font-face {
//   font-family: 'Optima Italic';
//   font-style: normal;
//   font-weight: normal;
//   src: local('Optima Italic'), url('../fonts/Optima_Italic.woff') format('woff');
// }


// @font-face {
//   font-family: 'Optima Medium';
//   font-style: normal;
//   font-weight: normal;
//   src: local('Optima Medium'), url('../fonts/Optima Medium.woff') format('woff');
// }


// @font-face {
//   font-family: 'Optima Bold';
//   font-style: normal;
//   font-weight: normal;
//   src: local('Optima Bold'), url('../fonts/OPTIMA_B.woff') format('woff');
// }

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Raleway:wght@400;800&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@import "utils/reset";
@import "~rfs/scss";
@import '~sass-mediaqueries/_media-queries';
// @import 'grid/grid.scss';
@import 'components/**/**/*.scss';